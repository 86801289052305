<template>
  <div>
    <Pane />

    <div class="container">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit" :form="form">
        <a-row class="form-row">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="区域名称">
              <a-input v-decorator="[
                'areaName',
                { rules: [{ required: true, message: '请输入' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class="">负责人</span>

              <div class="left" style="margin-top: 4px">
                <a-button style="margin-right: 4px" block @click="add">
                  <div class="left">
                    <div v-if="areaCharger.name">{{ areaCharger.name }}</div>
                    <div v-else>请选择</div>
                  </div>
                </a-button>

                <a-button icon="delete" :disabled="!areaCharger.name" @click="deleteAreaCharger"></a-button>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="上级区域">
              <a-cascader placeholder="" changeOnSelect v-decorator="[
                'pid',
                { rules: [{ required: true, message: '请选择！' }] },
              ]" :options="tree" :fieldNames="{
  label: 'areaName',
  value: 'key',
  children: 'children',
}" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchDetail, edit } from "@/api/setting/area";

import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "editArea",

  data() {
    return {
      form: this.$form.createForm(this),
      id: null,

      areaCharger: {},
    };
  },

  computed: {
    ...mapState("area", ["rawList", "areaList"]),
    tree() {
      return [
        {
          id: "顶层区域",
          key: "顶层区域",
          areaName: "顶层区域",
          pid: null,
          children: this.areaList,
        },
      ];
    },

    ...mapState("project", ["selectedList", "selectEmployeeVisible"]),
  },

  watch: {
    selectEmployeeVisible(newValue) {
      // 当选择人员modal被关闭时触发
      if (!newValue && this.selectedList.length > 0) {
        const obj = this.selectedList[0];
        this.areaCharger = obj;
        // 重置选中的状态
        this.setSelectedList([]);
      }
    },
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      this.getList().then(() => {
        fetchDetail({
          id,
        }).then((res) => {
          const pidList = [];

          this.findParentId(pidList, res.id);
          pidList.unshift("顶层区域");
          pidList.pop();

          console.log("pidList", pidList);

          this.form.setFieldsValue({
            areaName: res.areaName,
            pid: pidList,
          });

          this.areaCharger.userId = res.areaCharger;
        });
      });
    }
  },

  methods: {
    ...mapActions("area", ["getList"]),

    ...mapMutations("project", [
      "setSelectEmployeeVisible",
      "setSelectedList",
      "setSingle",
    ]),

    add() {
      this.setSelectEmployeeVisible(true);
      this.setSingle(true);
      // if (value.name) {
      //   this.setSelectedList([value]);
      // }
    },

    deleteAreaCharger() {
      this.areaCharger = {};
    },

    findParentId(result, id) {
      const res = this.rawList.find((item) => item.id === id);

      if (res) {
        result.unshift(res.id);
        this.findParentId(result, res.pid);
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let pid = values.pid[values.pid.length - 1];

          if (pid === "顶层区域") {
            pid = null;
          }

          edit({
            ...values,
            id: this.id,
            pid,
          }).then(() => {
            this.getList();
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
